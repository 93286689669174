import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import ArticlesList from "./ArticlesList"

const PageTaxa = ({ data, location }) => {

  const siteTitle = `Title`
  const posts = data.allPost.nodes
  const pageTitle = data.allTaxon.nodes[0].label

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={pageTitle} />
        <div className="container">
          <p>
            No blog posts found. Add markdown posts to "content/blog" (or the
            directory you specified for the "gatsby-source-filesystem" plugin in
            gatsby-config.js).
          </p>
        </div>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={pageTitle} />
      <div className="container">
        <ArticlesList
          posts={posts}
          title={pageTitle}
        />
      </div>
    </Layout>
  )
}

export default PageTaxa

export const taxaPageQuery = graphql`
  query TaxaPageQuery($taxonId: [String]) {
    allTaxon(filter: {id: {in: $taxonId}}) {
      nodes {
        label
      }
    }
    allPost(filter: {taxons: {elemMatch: {id: {in: $taxonId}}}}) {
      nodes {
        id
        title
        timeUpdate
        logLine
        poster
        tags {
          id
          label
        }
      }
    }
  }
`